import { FC, useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import clsx from "clsx";
import * as Yup from "yup";
import { useFormik } from "formik";
import { MultiSelect } from "react-multi-select-component";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import CustomerDataContext from "../../../store/CustomerData-context";
import ErrorMessage1 from "../../components/ErrorMessage1";
import {
  getUser,
  menuAccessOptions,
  siteAccessOptions,
  cognito_verify_reset_resend,
  // site_admin_access_controls,
  editUserHandler,
  checkUserStatus,
  changeUserStatus,
} from "./userManagement-hooks";
import Alert1 from "../../components/Alert1";
import ConstantsContext from "../../../store/Constants-context";
import Cookies from "universal-cookie";
import { Breadcrumb } from "../../../_metronic/layout/components/header/Breadcrumb";
import { AlertWidget, ModalWidget } from "../../UI";
import "./UserManagement.css";
// import { TwoFAuth } from "../../modules/2FAuth";

const cookies = new Cookies();
const userDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(1, "Minimum 1 character required.")
    .max(50, "Maximum 50 characters allowed.")
    .required("First name is required"),
  lastName: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(1, "Minimum 1 character required.")
    .max(50, "Maximum 50 characters allowed.")
    .required("Last name is required"),
  cognitoEmail: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .email("Wrong email format")
    .min(3, "Minimum 3 characters required.")
    .max(50, "Maximum 50 characters required.")
    .required("Email is required"),
  cognitoUsername: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(1, "Minimum 1 character required.")
    .max(128, "Maximum 128 characters allowed.")
    .required("Username is required"),
  // mobile: Yup.string()
  //   .trim("Cannot include leading and trailing spaces")
  //   .min(8, "Minimum 8 characters required.")
  //   .max(13, "Maximum 12 characters required."),
});

const EditUser: FC = () => {
  cookies.get("site_admin") !== "1" && window.location.replace("/"); // this page is only accessible to site_admins
  const userID = window.location.pathname.split("/")[2];

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cognitoEmail, setEmail] = useState("");
  const [cognitoUsername, setUsername] = useState("");
  const [siteAdmin, setSiteAdmin] = useState(false);
  const [disableSiteAccess, setDisableSiteAccess] = useState(false);
  const [disableMenuAccess, setDisableMenuAccess] = useState(false);
  // const [mobile, setMobile] = useState("");
  const [resendOption, setResendOption] = useState<boolean | undefined>(undefined);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [dispAlertMessage, setDispAlertMessage] = useState<string | boolean>(
    ""
  );
  const [menuAccess, setMenuAccess] = useState<
    { value: string; label: string }[]
  >([]);
  const [previousMenuAccess, setPreviousMenuAccess] = useState<
      { value: string; label: string }[]
  >([]);
  const [siteAccess, setSiteAccess] = useState<
    { value: string; label: string }[]
  >([]);
  const [previousSiteAccess, setPreviousSiteAccess] = useState<
      { value: string; label: string }[]
  >([]);
  const [siteAccessError, setSiteAccessError] = useState(false);
  const [menuAccessError, setMenuAccessError] = useState(false);
  const [disableAccessModal, setDisableAccessModal] = useState(false);
  // const [twoStepVerifyModal, setTwoStepVerifyModal] = useState(false);

  const [userSiteAccess, setUserSiteAccess] = useState([]);
  const [userMenuAccess, setUserMenuAccess] = useState([]);
  const [cognitoIdentity, setCognitoIdentity] = useState("");
  const [pageLoad, setPageLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loginDetailsSent, setLoginDetailsSent] = useState("Resend login details");

  const intl = useIntl();
  const customerCtx = useContext(CustomerDataContext);
  const constantsCtx = useContext(ConstantsContext);

  useEffect(() => {
    getUser(
      userID,
      setFirstName,
      setLastName,
      setEmail,
      setUsername,
      // setMobile,
      setUserSiteAccess,
      setUserMenuAccess,
      setSiteAdmin,
      setCognitoIdentity,
      setPageLoad
    );
  }, []);

  useEffect(() => {
    cognitoUsername && cognitoUsername.length > 0 &&
      checkUserStatus(cognitoUsername, setResendOption, setVerifyEmail, formik);
  }, [cognitoUsername]);

  useEffect(() => {
    // If the user is site admin, select all sites
    if ( pageLoad ) {
      const siteAccessMultiSelectDropdown = document
          .getElementById("siteAccess")!
          .getElementsByClassName(
              "dropdown-container"
          ) as HTMLCollectionOf<HTMLElement>;
      const menuAccessMultiSelectDropdown = document
          .getElementById("menuAccess")!
          .getElementsByClassName(
              "dropdown-container"
          ) as HTMLCollectionOf<HTMLElement>;

      if (siteAdmin) {
        // Give all access to all sites
        customerCtx.sites.length > 0 && setSiteAccess(siteAccessOptions(customerCtx.sites));
        siteAccessMultiSelectDropdown[0].style.backgroundColor = "#f3f3f3";
        setDisableSiteAccess(true);
        setSiteAccessError(false);

        // Give all access to menu Options
        setMenuAccess(menuAccessOptions(customerCtx.menu_config));
        menuAccessMultiSelectDropdown[0].style.backgroundColor = "#f3f3f3";
        setDisableMenuAccess(true);
        // setMenuAccessError(false);
      }else{
        //Reset site access
        customerCtx.sites.length > 0 && setSiteAccess(previousSiteAccess);
        siteAccessMultiSelectDropdown[0].style.backgroundColor = "transparent";
        setDisableSiteAccess(false);
        //Reset menu access
        setMenuAccess(previousMenuAccess);
        menuAccessMultiSelectDropdown[0].style.backgroundColor = "transparent";
        setDisableMenuAccess(false);
      }
    }
  }, [siteAdmin]);

  useEffect(() => {
    let user_site_access:any[] = siteAccessOptions(customerCtx.sites, userSiteAccess);
    if( user_site_access.length > 0 ) {
      setSiteAccess(user_site_access);
      setPreviousSiteAccess(user_site_access);
    }
  }, [userSiteAccess]);

  useEffect(() => {
    let selectedMenuAccessOptions:any[] = menuAccessOptions(userMenuAccess);
    setMenuAccess(selectedMenuAccessOptions);
    setPreviousMenuAccess(selectedMenuAccessOptions);
  }, [userMenuAccess]);

  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      cognitoEmail,
      cognitoUsername,
      siteAdmin,
      // twoStepVerify: false,
      // mobile,
      disableAccess: false,
    },
    validationSchema: userDetailsSchema,
    onSubmit: async (values) => {
      await editUserHandler(
        setLoading,
        menuAccess,
        siteAccess,
        userID,
        values,
        cognitoIdentity,
        setSuccess,
        setError
      );
      setTimeout(() => {
        setSuccess(false);
        formik.setTouched({firstName: false});  //   this resetted the touch value for all fields
      }, 3000);
    },
    enableReinitialize: true,
  });

  const EditUserBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU.SETUP" }),
      path: "/setup.html",
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: "MENU.USERACCOUNTS" }),
      path: "/users",
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: "MENU.EDITUSERACCOUNT" }),
      path: `/users/${userID}`,
      isSeparator: false,
      isActive: true,
    },
  ];
  return (
    <>
      {dispAlertMessage && <AlertWidget message={dispAlertMessage} />}
      <Breadcrumb
        pageBreadcrumbs={EditUserBreadCrumbs}
        activePageName={"user_accounts"}
      />
      {/* Setting Active Page Name in the Context */}
      <PageTitle activePageName={"user_accounts"}></PageTitle>

      {/* DISABLE ACCESS MODAL */}
      <ModalWidget
        title={formik.values.disableAccess ? "Enable User Access" : "Disable User Access"}
        modalBody={
          <p style={{ fontSize: "17px" }}>
            Are you sure you want to{" "}
            {formik.values.disableAccess ? "enable" : "disable"}{" "}
            <b>{cognitoUsername}</b>?
          </p>
        }
        closeModal={() => {
          formik.setFieldValue("disableAccess", formik.values.disableAccess);
          setDisableAccessModal(false);
        }}
        showModal={disableAccessModal}
        closeBtnText="Close"
        confirmBtnText="Confirm"
        confirmBtnAction={() => {
          formik.setFieldValue("disableAccess", !formik.values.disableAccess);
          setDisableAccessModal(false);
          if (formik.values.disableAccess) {
            changeUserStatus(cognitoUsername, "enable_user");
            setDispAlertMessage("User enabled successfully!");
          } else {
            changeUserStatus(cognitoUsername, "disable_user");
            setDispAlertMessage("User disabled successfully!");
          }
        }}
      />
      {/* TWO FACTOR AUTH MODAL */}
      {/*<TwoFAuth*/}
      {/*  formik={formik}*/}
      {/*  username={username}*/}
      {/*  twoStepVerifyModal={twoStepVerifyModal}*/}
      {/*  setTwoStepVerifyModal={setTwoStepVerifyModal}*/}
      {/*/>*/}
      {/* begin::Row */}
      <div className="row gy-5 gx-xl-8">
        <div className="col-xxl-12">
          <div className="card card-custom">
            {/* end::Header */}
            <div className="card-header">
              <div className="card-title">
                {intl.formatMessage({ id: "MENU.EDITUSERACCOUNT" })}
              </div>
            </div>
            <div className="card-body">
              {pageLoad && (<form
                onSubmit={(e) => {
                  e.preventDefault();
                  let foundErrors = false;
                  if (siteAccess.length === 0) {
                    setSiteAccessError(true);
                    foundErrors = true;
                  }
                  // if (menuAccess.length === 0) {
                  //   setMenuAccessError(true);
                  //   foundErrors = true;
                  // }
                  if (foundErrors === false) formik.handleSubmit();
                }}
              >
                <div className="row mb-10">
                  <div className="col-lg-6 col-xl-6">
                    <label className="col-lg-12 col-form-label">
                      First name <small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="text"
                      className={clsx(
                        "form-control form-control-solid px-5",
                        {
                          "is-invalid":
                            formik.touched.firstName && formik.errors.firstName,
                        },
                        {
                          "is-valid":
                            formik.touched.firstName &&
                            !formik.errors.firstName,
                        }
                      )}
                      {...formik.getFieldProps("firstName")}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={formik.errors.firstName}
                      />
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6">
                    <label className="col-lg-12 col-form-label">
                      Last name <small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="text"
                      className={clsx(
                        "form-control form-control-solid px-5",
                        {
                          "is-invalid":
                            formik.touched.lastName && formik.errors.lastName,
                        },
                        {
                          "is-valid":
                            formik.touched.lastName && !formik.errors.lastName,
                        }
                      )}
                      {...formik.getFieldProps("lastName")}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={formik.errors.lastName}
                      />
                    )}
                  </div>
                </div>
                <div className="row mb-10">
                  <div className="col-lg-6 col-xl-6">
                    <label className="col-lg-12 col-form-label">
                      Email <small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="email"
                      className={clsx(
                        "form-control form-control-solid px-5",
                        {
                          "is-invalid":
                            formik.touched.cognitoEmail && formik.errors.cognitoEmail,
                        },
                        {
                          "is-valid":
                            formik.touched.cognitoEmail && !formik.errors.cognitoEmail,
                        }
                      )}
                      {...formik.getFieldProps("cognitoEmail")}
                    />
                    {formik.touched.cognitoEmail && formik.errors.cognitoEmail && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={formik.errors.cognitoEmail}
                      />
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6">
                    <label className="col-lg-12 col-form-label">
                      Username <small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="text"
                      disabled
                      className={clsx(
                        "form-control form-control-solid px-5",
                        {
                          "is-invalid":
                            formik.touched.cognitoUsername && formik.errors.cognitoUsername,
                        },
                        {
                          "is-valid":
                            formik.touched.cognitoUsername && !formik.errors.cognitoUsername,
                        }
                      )}
                      {...formik.getFieldProps("cognitoUsername")}
                    />
                    {formik.touched.cognitoUsername && formik.errors.cognitoUsername && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={formik.errors.cognitoUsername}
                      />
                    )}
                  </div>
                </div>
                <div className="row mb-10">
                  <div className="col-lg-6 col-xl-6">
                    &nbsp;
                    {/*<label className="col-lg-12 col-form-label">*/}
                    {/*  Mobile*/}
                    {/*</label>*/}
                    {/*<input*/}
                    {/*  type="text"*/}
                    {/*  className={clsx(*/}
                    {/*    "form-control form-control-solid px-5",*/}
                    {/*    {*/}
                    {/*      "is-invalid":*/}
                    {/*        formik.touched.mobile && formik.errors.mobile,*/}
                    {/*    },*/}
                    {/*    {*/}
                    {/*      "is-valid":*/}
                    {/*        formik.touched.mobile && !formik.errors.mobile,*/}
                    {/*    }*/}
                    {/*  )}*/}
                    {/*  {...formik.getFieldProps("mobile")}*/}
                    {/*/>*/}
                    {/*{formik.touched.mobile && formik.errors.mobile && (*/}
                    {/*  <ErrorMessage1*/}
                    {/*    color={constantsCtx.ERROR_MESSAGE_COLOR_RED}*/}
                    {/*    errorMessage={formik.errors.mobile}*/}
                    {/*  />*/}
                    {/*)}*/}
                  </div>
                  <div className="col-lg-6 col-xl-6" id="menuAccess">
                    <label className="col-lg-12 col-form-label">
                      Menu Access Controls{" "}
                      {/*<small style={{ color: "red" }}>*</small>*/}
                    </label>
                    <MultiSelect
                      options={menuAccessOptions(customerCtx.menu_config)}
                      value={menuAccess}
                      disabled={disableMenuAccess}
                      hasSelectAll={true}
                      onChange={(e: any) => {
                        setMenuAccess(e);
                        // setMenuAccessError(false);
                        setPreviousMenuAccess(e);
                      }}
                      labelledBy="Select Menu Items"
                    />
                  </div>
                </div>

                <div className="row mb-10">
                  <div className="col-lg-3 col-xl-3">
                    <label className="form-check form-check-custom form-check-solid form-switch mb-5">
                      {/* {console.log("siteAdmin >>> ", siteAdmin)} */}
                      <input
                        className="form-check-input"
                        type="checkbox"
                        {...formik.getFieldProps("siteAdmin")}
                          onClick={() => {
                            setSiteAdmin(!siteAdmin);
                          }}
                        // onClick={() => {
                        //   site_admin_access_controls(
                        //     customerCtx.menu_config,
                        //     customerCtx.sites,
                        //     menuAccess,
                        //     siteAdmin,
                        //     userMenuAccess,
                        //     userSiteAccess,
                        //     setSiteAdmin,
                        //     setMenuAccessError,
                        //     setSiteAccess,
                        //     setMenuAccess
                        //   );
                        // }}
                        checked={formik.values.siteAdmin}
                      />
                      <span className="form-check-label col-form-label">
                        Site Administrator Access
                      </span>
                    </label>

                    <div className="d-flex p-1 mb-10" style={{ marginTop: "-2rem", marginLeft: "3.5rem", width: "275px" }}>
                      <div className="d-flex flex-column">
                        <span>Grants view/edit ability to all data, tools and settings</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-xl-3">
                    <label className="form-check form-check-custom form-check-solid form-switch mb-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        {...formik.getFieldProps("disableAccess")}
                        checked={formik.values.disableAccess}
                        onChange={() => setDisableAccessModal(true)}
                      />
                      <span className="form-check-label col-form-label">
                        Disable User Account Access
                      </span>
                    </label>
                  </div>

                  {/*<div className="col-lg-2 col-xl-2">*/}
                  {/*  {resendOption === false && (*/}
                  {/*    <label className="form-check form-check-custom form-check-solid form-switch mb-5">*/}
                  {/*      <input*/}
                  {/*        className="form-check-input"*/}
                  {/*        type="checkbox"*/}
                  {/*        {...formik.getFieldProps("twoStepVerify")}*/}
                  {/*        checked={formik.values.twoStepVerify}*/}
                  {/*        onChange={() => setTwoStepVerifyModal(true)}*/}
                  {/*      />*/}
                  {/*      <span className="form-check-label col-form-label">*/}
                  {/*        Enable Two Step Verification*/}
                  {/*      </span>*/}
                  {/*    </label>*/}
                  {/*  )}*/}
                  {/*</div>*/}

                  <div className="col-lg-6 col-xl-6" id="siteAccess">
                    <label className="col-lg-12 col-form-label">
                      Site Access Controls{" "}
                      <small style={{ color: "red" }}>*</small>
                    </label>
                    <MultiSelect
                      options={siteAccessOptions(customerCtx.sites)}
                      value={siteAccess}
                      disabled={disableSiteAccess}
                      hasSelectAll={true}
                      onChange={(e: any) => {
                        setSiteAccess(e);
                        setSiteAccessError(false);
                        setPreviousSiteAccess(e);
                      }}
                      labelledBy="Select Sites"
                    />
                    {siteAccessError === true && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={"Please select at least one site"}
                      />
                    )}
                  </div>
                </div>
                {resendOption === true && (
                  <div className="row mb-10">
                    <div className="col-lg-6 col-xl-6">
                      <label className="form-check form-check-custom form-check-solid form-switch mb-5">
                        <span className="form-check-label text-warning px-3">
                          <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                          Registration process not complete
                        </span>
                        <button
                          className="btn btn-ll"
                          name="action"
                          onClick={(e) => {
                            e.preventDefault();
                            setLoginDetailsSent("Resending Now");
                            cognito_verify_reset_resend(
                                "resend_welcome_email",
                                formik,
                                setLoginDetailsSent
                            );
                          }}
                          onMouseOver={(e) => {
                              setLoginDetailsSent("Resend Login Details");
                            }
                          }
                        >
                          {loginDetailsSent}
                        </button>
                      </label>
                    </div>
                  </div>
                )}
                {verifyEmail === true && (
                  <div className="row mb-10">
                    <div className="col-lg-10 col-xl-8">
                      <label className="form-check form-check-custom form-check-solid form-switch mb-5">
                        <span className="form-check-label text-warning px-3">
                          <i className="bi bi-exclamation-triangle-fill"></i>{" "}
                          Email verification
                        </span>
                        <button
                          className="btn btn-ll"
                          name="action"
                          onClick={(e) => {
                            e.preventDefault();
                            cognito_verify_reset_resend("verify_email", formik, null);
                          }}
                        >
                          Verify Email
                        </button>
                      </label>
                    </div>
                  </div>
                )}
                <div className="row mb-10">
                  {/* begin::Success Alert */}
                  {!loading && success && (
                    <Alert1
                      alertTitle="Success"
                      alertMessage="User Info Updated"
                      success={true}
                    />
                  )}
                  {/* end::Success Alert */}

                  {/* begin::Error Alert */}
                  {!loading && error && (
                    <Alert1
                      alertTitle={"Error"}
                      alertMessage={"Something went wrong"}
                      success={false}
                    />
                  )}
                  {/* end::Error Alert */}
                </div>
                <div className="row mb-10">
                  <div className="col-lg-6 col-xl-6">
                    <Link
                      className="btn btn-active-light btn-color-muted cancelButton"
                      to="/users"
                    >
                      Cancel
                    </Link>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <button
                      type="submit"
                      className="btn btn-ll"
                      disabled={
                        formik.isSubmitting || !formik.isValid || success
                      }
                    >
                      {success && !loading && (
                          <span className="indicator-label">Save</span>
                      )}
                      {!success && loading && !error && (
                        <span className="indicator-progress">
                          Please wait...{" "}
                        </span>
                      )}
                      {!success && !loading && (
                        <span className="indicator-label">Save</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { EditUser };
